import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import DashboardPng from "./dashboard.PNG"
import Calorie from "./calorie.PNG"
import Ekart from "./ecommerce.PNG"
function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              img={DashboardPng}
              isBlog={false}
              title="Ecommerce Dashboard"
              description="Ecommerce dashboard build with react.js, Material-UI. Have features which allows user for add products and edit & delete."
              link="https://ecommerce-app-admin-pannel.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              img={Ekart}
              isBlog={false}
              title="The shopkart"
              description="The real world ecommerce app build using Next & Tailwind CSS. User can search add to wishlist and cart checkout Page with beautiful UI"
              link="https://shopkart-app.vercel.app/"
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              img="https://user-images.githubusercontent.com/53529978/163843729-a83b9823-c216-47e4-976b-55ee4b677485.PNG"
              isBlog={false}
              title="Weather app"
              description="Weaher app build with react.js.Have feautres of search where user can search of particular city and get weather details"
              link="https://github.com/have01/Weather-app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              img="https://user-images.githubusercontent.com/87645745/213860435-ef02b36f-adbd-4517-b103-174c3dcb7be9.png"
              isBlog={false}
              title="Resume builder 2.0"
              description="Build your resume fast with multiple template "
              link="https://resume-builder-master.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              img={Calorie}
              isBlog={false}
              title="Calorie tracker"
              description="Calorie tracker using Javascript. where user can track calorie "
              link="https://github.com/have01/Bmi-Calculator"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              img="https://user-images.githubusercontent.com/53529978/218430459-59be3299-a03d-4cd5-83e6-c86c56c6690c.PNG"
              isBlog={false}
              title="Recepie Book"
              description="Search any recepie in the world and cook food at home"
              link="https://extraordinary-macaron-121e8c.netlify.app/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
