import React, { useState, useEffect } from "react";
import { Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import resumeLink from "./Dheerendra.pdf";
import { AiOutlineDownload } from "react-icons/ai";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ResumeNew() {
  const [width, setWidth] = useState(1200);

  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <div>
      <Container fluid className="resume-section">
        <Particle />
        <Row style={{ justifyContent: "center", position: "relative" }}>

        </Row>
        <React.Fragment>
          <h1 className="title mt-6 pt-2">Dheerendra resume</h1>
        </React.Fragment>

        <div className="resume resume_pdf">
          <Document file={resumeLink} className="d-flex flex-col ">
            <Page pageNumber={1} scale={width > 786 ? 1.7 : 0.6} />
          </Document>

        </div>


      </Container>
    </div>
  );
}

export default ResumeNew;
