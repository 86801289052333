import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiJavascript1,
  DiReact,
  DiNext,
  DiHtml5,
} from "react-icons/di";
import {
  SiCss3,
  SiBootstrap,
} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px", }}>

      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
      </Col>

      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">

        <i class="devicon-nodejs-plain-wordmark"></i>

      </Col>
      <Col xs={4} md={2} className="tech-icons">

        <i class="devicon-nextjs-original"></i>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <i class="devicon-typescript-plain"></i>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <i class="devicon-tailwindcss-original-wordmark"></i>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <i class="devicon-bootstrap-plain"></i>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <i class="devicon-materialui-plain"></i>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <i class="devicon-gatsby-plain-wordmark colored"></i>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <i class="devicon-express-original colored"></i>
      </Col>






    </Row>
  );
}

export default Techstack;
