import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "center" }}>
            Hi Everyone, I am <span className="purple">Dheerendra kumar </span>
            from <span className="purple"> Kanpur, India.</span>
            <br />I completed my bachelors degree in Computer <br /> science from Quantum school of Technology,Roorkee.

          </p>

          <p className="purple">
            I have 1+ year professional experience as Software developer.

          </p>
          <p style={{ color: "rgb(155 126 172)" }}>
            "Build and grow || Learn and grow"{" "}
          </p>
          <footer className="blockquote-footer">Dheerendra</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
